import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Declarative D3 transitions with React 16.3",
  "description": "The new React 16.3 brings some changes to the ecosystem that change how we go about integrating React and D3 to build data visualizations.",
  "date": "2018-04-12T08:00:00.000Z",
  "published": "2018-04-12T08:00:00.000Z",
  "image": "./img/blog-wp-content-uploads-2018-04-carbon-945x1024.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The new React 16.3 brings some changes to the ecosystem that change how we go about integrating React and D3 to build data visualizations.`}</p>
    <p><inlineCode parentName="p">{`componentWillReceiveProps`}</inlineCode>{`, `}<inlineCode parentName="p">{`componentWillUpdate`}</inlineCode>{` and `}<inlineCode parentName="p">{`compnentWillMount`}</inlineCode>{` are on their way out. They were great for making `}<a parentName="p" {...{
        "href": "https://swizec.com/reactd3js"
      }}>{`React and D3`}</a>{` happy together, but they cause issues with the async rendering that the React team is planning for React 17.`}</p>
    <p>{`You tend to use those now-deprecated lifecycle methods to update D3 objects`}{`'`}{` internal state. Things like setting scale domains and ranges, updating complex D3 layouts, setting up transitions, etc.`}</p>
    <p>{`But you don`}{`'`}{`t need to! You can do it all with the new lifecycle API.`}</p>
    <p>{`Here`}{`'`}{`s a small example of building a transition with React 16.3. Using only approved lifecycle callbacks and the new `}<inlineCode parentName="p">{`ref`}</inlineCode>{` API.`}</p>
    <lite-youtube {...{
      "videoid": "dipl9j1gpM8",
      "videostartat": "0"
    }}></lite-youtube>
    <p>{`You can play with it on CodeSandbox 👇`}</p>
    <h2 {...{
      "id": "how-it-works"
    }}>{`How it works`}</h2>
    <p>{`The core issue we`}{`'`}{`re working around is that when you pass new props into a component, React re-renders. This happens instantly. Because the re-render is instant, you don`}{`'`}{`t have time to show a nice transition going into the new state.`}</p>
    <p>{`You can solve this by rendering your component from `}<inlineCode parentName="p">{`state`}</inlineCode>{` instead of props and keeping that state in sync.`}</p>
    <p>{`Something like this 👇`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "890px",
          "textAlign": "center",
          "fontStyle": "italic"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/31213d3ed35d7412c7ea00b25f65bce0/40601/blog-wp-content-uploads-2018-04-carbon-945x1024.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.5201793721973%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'433\\'%20viewBox=\\'0%200%20400%20433\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M21%2030c-2%201-3%20370%200%20372%202%203%20356%203%20358%200%203-2%202-371%200-372H21\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/31213d3ed35d7412c7ea00b25f65bce0/ca0a1/blog-wp-content-uploads-2018-04-carbon-945x1024.webp 223w", "/static/31213d3ed35d7412c7ea00b25f65bce0/75680/blog-wp-content-uploads-2018-04-carbon-945x1024.webp 445w", "/static/31213d3ed35d7412c7ea00b25f65bce0/8d1ba/blog-wp-content-uploads-2018-04-carbon-945x1024.webp 890w", "/static/31213d3ed35d7412c7ea00b25f65bce0/a70f5/blog-wp-content-uploads-2018-04-carbon-945x1024.webp 945w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/31213d3ed35d7412c7ea00b25f65bce0/e92b6/blog-wp-content-uploads-2018-04-carbon-945x1024.png 223w", "/static/31213d3ed35d7412c7ea00b25f65bce0/e66bf/blog-wp-content-uploads-2018-04-carbon-945x1024.png 445w", "/static/31213d3ed35d7412c7ea00b25f65bce0/4ef49/blog-wp-content-uploads-2018-04-carbon-945x1024.png 890w", "/static/31213d3ed35d7412c7ea00b25f65bce0/40601/blog-wp-content-uploads-2018-04-carbon-945x1024.png 945w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/31213d3ed35d7412c7ea00b25f65bce0/4ef49/blog-wp-content-uploads-2018-04-carbon-945x1024.png",
              "alt": "blog wp content uploads 2018 04 carbon 945x1024",
              "title": "blog wp content uploads 2018 04 carbon 945x1024",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We define a `}<inlineCode parentName="p">{`Ball`}</inlineCode>{` class-based component. Its state has a single attribute, `}<inlineCode parentName="p">{`x`}</inlineCode>{`, which is set to the `}<inlineCode parentName="p">{`props.x`}</inlineCode>{` value by default. That way our Ball component starts off rendered at the position the caller wants.`}</p>
    <p>{`Next, we create a new `}<inlineCode parentName="p">{`circleRef`}</inlineCode>{` using the React 16.3 ref API. We use this reference to give D3 control of the DOM so it can run our transition.`}</p>
    <p>{`That happens in `}<inlineCode parentName="p">{`componentDidUpdate`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{}}>{`componentDidUpdate() {
    d3
      .select(this.circleRef.current)
      .transition()
      .duration(1000)
      .ease(d3.easeCubicInOut)
      .attr("cx", this.props.x)
      .on("end", () =>
        this.setState({
          x: this.props.x
        })
      );
  }
`}</code></pre>
    <p>{`React calls `}<inlineCode parentName="p">{`componentDidUpdate`}</inlineCode>{` whenever we change our component`}{`'`}{`s props.`}</p>
    <p>{`We use `}<inlineCode parentName="p">{`d3.select()`}</inlineCode>{` to give D3 control of the DOM node, run a transition that lasts `}<inlineCode parentName="p">{`1000`}</inlineCode>{` milliseconds, define an easing function, and change the `}<inlineCode parentName="p">{`cx`}</inlineCode>{` attribute to the new value we got from props.`}</p>
    <p>{`Right now, `}<inlineCode parentName="p">{`state`}</inlineCode>{` holds the old position and `}<inlineCode parentName="p">{`props`}</inlineCode>{` hold the new desired position.`}</p>
    <p>{`When our transition ends, we update state to match the new reality. This ensures React doesn`}{`'`}{`t get upset with us.`}</p>
    <p>{`At the very end, we have our `}<inlineCode parentName="p">{`render()`}</inlineCode>{` function. It returns an SVG circle. Don`}{`'`}{`t forget to set the `}<inlineCode parentName="p">{`ref`}</inlineCode>{` to `}<inlineCode parentName="p">{`this.circleRef`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "declarative-"
    }}>{`Declarative 💪`}</h2>
    <p>{`We made sure our implementation is completely declarative. To the outside world at least.`}</p>
    <p>{`Making the ball jump left to right looks like this:`}</p>
    <pre><code parentName="pre" {...{}}>{`// state = { ballLeft: false }
// ballJump() flip ballLeft state
// render()

          
        
`}</code></pre>
    <p>{`Our `}<inlineCode parentName="p">{`state`}</inlineCode>{` holds a flag that says whether our ball is on the left. If it is, we pass an `}<inlineCode parentName="p">{`x`}</inlineCode>{` prop value of `}<inlineCode parentName="p">{`15`}</inlineCode>{`, otherwise `}<inlineCode parentName="p">{`300`}</inlineCode>{`.`}</p>
    <p>{`When that value changes, the `}<inlineCode parentName="p">{`<Ball />`}</inlineCode>{` transitions itself to its new position. No need for us to worry.`}</p>
    <p>{`If we flip positions `}<em parentName="p">{`during`}</em>{` a transition, D3 is smart enough to stop the previous transition and start a new one. UI looks perfect.`}</p>
    <p>{`Try it. 🏀`}</p>
    <p><em parentName="p">{`PS: I`}{`'`}{`m thinking about updating `}<a parentName="em" {...{
          "href": "https://swizec.com/reactd3js"
        }}>{`my React+D3v4 book`}</a>{` for React 16.3 and D3v5. Would that interest you? `}<a parentName="em" {...{
          "href": "http://twitter.com/home?status=Yo%20@swizec%20you%20should%20update%20your%20React+D3v4%20book"
        }}>{`Tell me on Twitter`}</a>{`.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      